<template>
<div>
  <Header />

  <router-view></router-view>
  <!-- <Footer /> -->
</div>
</template>


<script>
import Header from "./views//Header";
// import Footer from "./views/Footer";
// import carrousel from './components/carrousel'

export default {
  name: "App",
  data() {
    return {
    
    };
  },

  components: {
    Header,
    // Footer,
  },

  // data: () => ({}),
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Ubuntu:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Spirax&display=swap");

html {

  body {
    scroll-behavior: smooth;
    a {
      color: black;
    }
  }
  body::-webkit-scrollbar {
    display: none !important;
  }
  li {
    color: black;
    a {
      color: black;
    }
  }
}
</style>
