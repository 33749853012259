<template>
  <div class="mainfffff">
    <!-- Showcase -->

    <!-- Stats -->
    <section class="stats">
      <div class="container">
        <h3 class="stats-heading text-center my-1 k-5">WHY IPAASOLUTION</h3>
        <p class="k-2">
          IPAA has the SOLUTION for all your need, just ask and we delivery to
          meet your needs.
        </p>
      </div>
    </section>

    <section class="languages">
      <div class="container">
        <div class="card animate__animated animate__backInRight">
          <h1>Medical Equipment’s</h1>
          <v-row>
            <v-col
              v-for="item in items"
              :key="item.id"
              class="d-flex child-flex"
              cols="6"
              sm="4"
            >
              <v-card flat tile class="d-flex">
                <v-img :src="item.src" aspect-ratio="1" class="grey lighten-2">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
          <!-- <img src="../assets/last-last.png" alt="" /> -->
          <span class="ls">
            IPAA SOLUTION has provided medical equipment, instruments, generic,
            and non-prescription products manufactured in US and Canada and
            distribute or sold mainly to south west Africa.
          </span>
        </div>
        <div class="card animate__animated animate__backInLeft">
          <h1>Food products and related items</h1>
          <!-- <img src="../assets/last-last1.png" alt="" /> -->
          <v-row>
            <v-col
              v-for="item in food"
              :key="item.id"
              class="d-flex child-flex"
              cols="6"
              sm="4"
            >
              <v-card flat tile class="d-flex">
                <v-img :src="item.src" aspect-ratio="1" class="grey lighten-2">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
          <p>
            IPAA SOLUTION has established several markets where turn-key
            services provide food products and related items from procurement
            and shipment, to inspection, delivery and assistance in obtaining
            financing. IPAA SOLUTIONS with its Partners are capable of providing
            these services for both bulk-shipment and consumer-packaged
            products. IPAA SOLUTION has experience in exporting items of every
            imaginable type, many by major U.S. manufacturers, such as, rice,
            beans, corn, chicken and so many others.
          </p>
        </div>
        <div class="card">
          <h1>Transport equipment and accessories</h1>
          <v-row>
            <v-col
              v-for="item in cars"
              :key="item.id"
              class="d-flex child-flex"
              cols="6"
              sm="4"
            >
              <v-card flat tile class="d-flex">
                <v-img :src="item.src" aspect-ratio="1" class="grey lighten-2">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
          <!-- <img src="../assets/last-last2.png" alt="" /> -->
          <p>
            IPAA SOLUTION has a diverse background in both "one-of-a-kind" and
            everyday items such as ambulances, bullet-resistant vehicles, buses,
            cranes, dump trucks, dumpsters, freight elevators, mobile platforms,
            refrigerated trucks, tactical vehicles, auto spare parts of any
            brand, aircraft and aircraft parts, lubricants and varied fluids,
            and fuel additives.
          </p>
        </div>
      </div>
    </section>

    <!-- Sub head -->
    <section class="features-sub-head py-3 block">
      <div class="block text">
        <h2>SERViCES</h2>
        <p class="container">
          From the most common items (such as household items), to the most
          unusual (such as a specially-commissioned sculpture for a national
          ceremony), IPAA SOLUTION has the expertise and the resources to
          accomplish virtually any task no matter how small or how large, such
          as:
        </p>
        <div class="caixa animate__animated animate__rollIn">
          <div class="caixa1">
            <span>
              Advising customers of all available options to fulfill any
              requirement.
            </span>
            <img src="../assets/ASSEMBELEIA-1024x683.jpg" alt="" />
          </div>
          <div class="caixa1">
            <span>
              Creating and developing any projects from draft/beginning to its
              end/conclusion, basis on the clients needs.
            </span>
            <img src="../assets/ilhamarginal.jpg" alt="" />
          </div>
        </div>

        <div class="caixa">
          <div class="caixa1">
            <span>
              Assistance in obtaining financing for its credit-worthy customers,
              from EXIM BANK, USDA, Local BANKS, etc.
              <ul>
                <li>
                  Assistance with local import regulations, where necessary.
                </li>
              </ul>
            </span>
            <img src="../assets/logo-governo.png" alt="" />
          </div>
          <div class="caixa1">
            <span>
              Procurement of products at the most competitive prices in the
              countries of their origin.
              <ul>
                <li>
                  It is IPAA SOLUTION's goal to offer its customer a turn-key
                  operation, with minimal concern and expense.
                </li>
              </ul>
            </span>
            <img src="../assets/luanda.jpg" alt="" />
          </div>
        </div>
      </div>
      <!-- <div class="container grid decoration">
        <div>
          <h1 class="md">Services</h1>
          <p>
            From the most common items (such as household items), to the most
            unusual (such as a specially-commissioned sculpture for a national
            ceremony), IPAA SOLUTION has the expertise and the resources to
            accomplish virtually any task no matter how small or how large, such
            as:
          </p>
          <ul>
            <li v-for="task in tasks" :key="task">{{ task.text }}</li>
          </ul>
        </div>
        <div class="cycle">
          <v-switch v-model="reverse" label="Toggle reverse"></v-switch>
          <v-timeline :reverse="reverse" :dense="$vuetify.breakpoint.smAndDown">
            <v-timeline-item v-for="n in 2" :key="n">
              <span slot="opposite">Tus eu perfecto</span>
              <v-card class="elevation-2">
                <v-card-title class="headline"> Lorem ipsum </v-card-title>
                <v-card-text>
                  Lorem ipsum dolor sit amet, no nam oblique veritus. Commune
                  scaevola imperdiet nec ut, sed euismod convenire principes at.
                  Est et nobis iisque percipit, an vim zril disputando
                  voluptatibus, vix an salutandi sententiae.
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
          <v-timeline :reverse="reverse" dense>
            <v-timeline-item v-for="n in 2" :key="n">
              <span slot="opposite">Tus eu perfecto</span>
              <v-card class="elevation-2">
                <v-card-title class="headline"> Lorem ipsum </v-card-title>
                <v-card-text>
                  Lorem ipsum dolor sit amet, no nam oblique veritus. Commune
                  scaevola imperdiet nec ut, sed euismod convenire principes at.
                  Est et nobis iisque percipit, an vim zril disputando
                  voluptatibus, vix an salutandi sententiae.
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </div>
      </div> -->
    </section>

    <div class="container">
      <div class="accordion" id="accordionExample">
        <div class="card">
          <div class="card-header" id="headingOne">
            <h1 class="mb-0">
              <button
                class="btn btn-block text-left"
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Other Equipment
              </button>
            </h1>
          </div>

          <div
            id="collapseOne"
            class="collapse show"
            aria-labelledby="headingOne"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              IPAA SOLUTION withs its partnership co., provides also heavy
              equipment (including back hoe loaders, bulldozers, compactors,
              excavators, pavers, wheel and truck loaders, continuous and
              standby power systems, drilling systems, etc.), facility
              programming, warehousing, construction management and
              administration, to purchasing construction material, components,
              furnishings, fixtures and equipment.
            </div>
            <div class="images">
              <!-- <img src="../assets/ippa.png" alt="" /> -->
              <v-carousel>
                <v-carousel-item
                  v-for="(item, i) in itemssss"
                  :key="i"
                  :src="item.src"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                ></v-carousel-item>
              </v-carousel>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingTwo">
            <h1 class="mb-0">
              <button
                class="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Products from Africa: Raw Material, Food, Clothing and related
                items
              </button>
            </h1>
          </div>
          <div
            id="collapseTwo"
            class="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              IPAA SOLUTION import from Africa selected products approved by the
              US government and AGOA agreement, such as, granite, African
              clothing style, lethal sandals, coffee and ethanol and other
              items.
            </div>
            <div class="images">
              <!-- <img src="../assets/afrp.png" alt="" /> -->
              <v-carousel hide-delimiters>
                <v-carousel-item
                  v-for="(item, i) in itemss"
                  :key="i"
                  :src="item.src"
                ></v-carousel-item>
              </v-carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
  data() {
    return {
      model: 0,
      // colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
      itemssss: [
        {
          src: require("../assets/luanda.jpg"),
        },
        {
          src: require("../assets/luanda.jpg"),
        },
        {
          src: require("../assets/luanda.jpg"),
        },
        {
          src: require("../assets/luanda.jpg"),
        },
        {
          src: require("../assets/luanda.jpg"),
        },
      ],
      itemss: [
        // {
        //   src: require("../assets/a4816258a0cf2b8907dea0a33f55a527.jpg"),
        // },
        {
          src: require("../assets/luanda.jpg"),
        },
        {
          src: require("../assets/luanda.jpg"),
        },
        {
          src: require("../assets/luanda.jpg"),
        },
      ],
      // colorss: [
      //   "indigo",
      //   "warning",
      //   "pink darken-2",
      //   "red lighten-1",
      //   "deep-purple accent-4",
      // ],
      // slides: ["First", "Second", "Third", "Fourth", "Fifth"],
      // tasks: [
      //   {
      //     text:
      //       "Advising customers of all available options to fulfill any requirement.",
      //   },
      //   {
      //     text:
      //       "Assistance in obtaining financing for its credit-worthy customers, from EXIM BANK, USDA, Local BANKS, etc.",
      //   },
      //   {
      //     text:
      //       "Creating and developing any projects from draft/beginning to its end/conclusion, basis on the clients needs.",
      //   },
      //   {
      //     text:
      //       "Procurement of products at the most competitive prices in the countries of their origin.",
      //   },
      //   { text: "Assistance with local import regulations, where necessary." },
      //   {
      //     text:
      //       "It is IPAA SOLUTION's goal to offer its customer a turn-key operation, with minimal concern and expense.",
      //   },
      // ],
      items: [
        {
          id: 1,
          src: require("../assets/luanda.jpg"),
        },
        {
          id: 2,
          src: require("../assets/luanda.jpg"),
        },
        {
          id: 3,
          src: require("../assets/luanda.jpg"),
        },
      ],
      food: [
        {
          id: 1,
          src: require("../assets/luanda.jpg"),
        },
        {
          id: 2,
          src: require("../assets/luanda.jpg"),
        },
        {
          id: 3,
          src: require("../assets/luanda.jpg"),
        },
      ],
      cars: [
        {
          id: 1,
          src: require("../assets/luanda.jpg"),
        },
        {
          id: 2,
          src: require("../assets/luanda.jpg"),
        },
        {
          id: 3,
          src: require("../assets/luanda.jpg"),
        },
      ],
      date: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped>
.mainfffff {
  background-color: #00263c;
  color: white;
  ul {
    padding-left: 40px;
    li {
      padding: 8px;
    }
  }

  .k-5 {
    margin-top: 8.25rem !important;
  }
  .k-2 {
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .dbata {
    text-align: center;
  }
  .space {
    margin-bottom: 50px;
  }

  .h1 {
    padding-top: 40px;
  }
  .kl0 {
    li {
      text-decoration: none;
      list-style-type: none;
    }
  }
  .dora {
    text-align: center;
  }
  .card {
    display: flex;
  }
  .decoration {
    img {
      box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    }
  }
  .block {
    color: black;
    min-height: 100vh;
    background-image: url("../assets/luanda.jpg");
    .text {
      padding-top: 3rem !important;
      padding-bottom: 1rem im !important;
      h2 {
        font-size: 3rem;
        text-align: center;
        font-weight: 400;
        line-height: 1.2;
        letter-spacing: 0.2rem;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
      .coll {
        color: crimson;
      }
    }
    .caixa {
      display: flex;
      margin-top: 9rem !important;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      flex: wrap;
      padding: 0 230px 0;

      .caixa1 {
        max-width: 500px;
        margin: 0 auto;
        overflow: auto;
        display: flex;
        align-items: center;
        p {
          // margin-top: 1rem;
          color: black;
          font-size: 1.4rem;
          margin-top: 5px;
          line-height: 2.5rem;
          font-weight: 300;
          letter-spacing: 0.05rem;
        }
        img {
          width: 100px;
          height: 100px;
          margin-left: 40px;
        }
      }
    }
  }
  .btn {
    background: #00263c;
  }
  // @media (max-width: 1000px) {
  //   .block {
  //     .text {
  //     }
  //     .caixa {
  //       .caixa1 {
  //       }
  //     }
  //   }
  // }
}

.main {
  color: #718096;
  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .title {
    text-align: center;
    padding-top: 40px;
    letter-spacing: 1.07px;
    h4 {
      font-size: 2rem;
      color: black;
      margin-top: 60px;
      font-weight: 700;
    }
    p {
      font-size: 20px;
    }
  }
  .design {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    margin: auto;
    min-height: 500px;
    border-radius: 20px;
    margin-bottom: 50px;

    h1 {
      font-weight: 600;
      color: black;
    }
    .left {
      width: 570px;
      img {
        width: 120px;
      }
      .d-one {
        h4 {
          text-align: center;
          padding-left: 188px;
          font-weight: 500;
          color: black;
        }
        .d-1 {
          display: flex;
          align-items: center;
          justify-content: center;
          .d-text {
            p {
              padding-left: 72px;
            }
          }
        }
      }
    }
    span {
      font-size: 14px;
    }
  }
  .other {
    padding: 30px 447px 50px;
    background-color: #f9f9f9;
    h2 {
      text-align: center;
      color: black;
    }
    h4 {
      text-align: center;
      margin-top: 70px;
      color: black;
    }
    p {
      text-align: center;
    }
  }
  .services {
    width: 1869px;
    margin-left: auto;
    margin-right: auto;
    padding: 30px 447px 50px;
    h1 {
      color: black;
      font-weight: 600;
    }
    ul {
      padding-left: 90px;
      padding-top: 20px;
      li {
        padding: 8px;
      }
    }
  }
}
</style>
