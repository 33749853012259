<template>
  <div class="bg">
    <div class="img">
      <!-- <img src="../assets/newneuralrmbg.png" alt /> -->
      <p>djdjdjdjdjdjdj</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "pagina-index",
  data() {
    return {
      user: {
        name: "pagina",
      },
    };
  },
  // mounted() {
  //   setTimeout(() => {
  //     this.$router.push("/home");
  //   }, 3000);
  // },
};
</script>

<style lang="scss" scoped>
.bg {
  background: #00263c;
  // min-height: 100vh; 
  .img {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // img {
  //   width: 70px;
  //   height: 70px;
  //   box-shadow: 0 0 10px 10px rgba(#ccffff, 0.3);
  //   animation-name: img;
  //   animation-duration: 3s;
  //   animation-iteration-count: infinite;
  //   @keyframes img {
  //     from {
  //       opacity: 0.6;
  //     }
  //     50% {
  //       opacity: 1;
  //     }
  //     to {
  //       opacity: 0.6;
  //     }
  //   }
  // }
}
}
</style>
